<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="5" order-sm="1">
				<v-subheader>Nuevo menú</v-subheader>
				<v-divider class="mb-2"></v-divider>
        <NuevoMenu
          @reload="$emit('reload')"
          :key="keyHelper"
          :value="menu"
          :categorias="categorias"
          :articulos="articulos"
          :escandallos="escandallos"
          :recetas="recetas"
        />
      </v-col>
      <v-col cols="12" md="7" order-sm="1">
        <v-subheader>Histórico de menús</v-subheader>
        <v-divider class="mb-2"></v-divider>
        <v-data-table
          fixed-header
          :items="menus.filter(e => !loading)"
          :headers="menusHeader"
          class="fixed-checkbox"
          item-key="idMenu"
          :single-select="false"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [0, -1],
          }"
          checkbox-color="secondary"
        >
          <template v-slot:body.prepend="{ headers }">
            <table-filters
              :headers="headers"
              :items="menus"
              v-model="inlineFilters"
            ></table-filters>
          </template>
          <template v-slot:item.n="{ item, index }">
            <td style="white-space: nowrap !important;padding-left: 22px !important;">{{ index+1 }}</td>
          </template>
          <template v-slot:item.precio="{ item }" style="text-align: right;">
            <td style="white-space: nowrap !important;padding-left: 22px !important;text-align: right;">{{ parseFloat(item.pDef || item.precioDesv).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</td>
          </template>
          <template v-slot:item.creado="{ item }" style="text-align: right;">
            <td :style="'white-space: nowrap !important;padding-left: 22px !important;color : ' + ((item.creado < new Date().getTime() - (90 * 24 * 60 * 60 * 1000)) ? 'var(--v-error-base)' : '')">
              {{ item.creado.toLocaleDateString('esp', { day : 'numeric', month : 'short', year : 'numeric' }).toLocaleUpperCase() }}
            </td>
          </template>
          <template v-slot:item.acciones="{ item }">
            <td style="white-space: nowrap !important;padding-left: 22px !important;">
              <div class="d-flex">
                <v-btn
                  @click="exportar(item)"
                  small
                  rounded
                  color="success"
                >
                  <v-icon left>mdi-file-pdf-box</v-icon>
                  Ver
                </v-btn>
                <v-btn
                  @click="setMenu(item)"
                  small
                  rounded
                  class="mx-1"
                  color="info"
                >
                  <v-icon left>mdi-update</v-icon>
                  Actualizar
                </v-btn>
                <v-btn
                  @click="deleteReceta(item)"
                  small
                  rounded
                  color="error"
                >
                  <v-icon left>mdi-delete</v-icon>
                  Eliminar
                </v-btn>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
		<ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { parseDate, perColumnFilter } from "@/utils/index.js";

export default {
  props: {
    loading: Boolean,
    categorias: Array,
    articulos: Array,
    escandallos: Array,
    recetas: Array,
    menus: Array,
  },
  components: {
    NuevoMenu: () => import("./NuevoMenu.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      menu: null,
      inlineFilters: {},
      keyHelper: 0,
      menusHeader: [],
      menusHeader: [
        { text: "Nº", value: "n", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Nombre", value: "nombre", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Precio", value: "precio", cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Creado", value: "creado", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Nota", value: "nota", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Acciones", value: "acciones", sortable: false, filterable: false },
      ].map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-1 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),
    };
  },
  watch: {
    menu() {
      this.keyHelper++;
    },
  },
  methods: {
    setMenu(i){
      this.menu = i;
      this.$forceUpdate()
    },
    exportar(i){
      console.log(i);
      var v = window.open('', '_blank','')
      v.document.write('<head><title>' + i.nombre + '</title><style>ul {font-size: 14px !important};</style></head>');
      var l = v.document.createElement('link');
      l.rel = 'stylesheet';
      l.href = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900';
      //titulo
      var wrapper = v.document.createElement('div')
      wrapper.style = "display:block;-webkit-print-color-adjust:exact !important;print-color-adjust:exact !important;"
      wrapper.innerHTML = '<div style="display:flex;justify-content: space-between"><div style="font-family: \'Roboto\', sans-serif;display:flex; align-items:center"><h3>' + i.nombre + '</h3></div></div><hr/></div>'

      var tabla = v.document.createElement('table')
      tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width: 100%"
      var thead = v.document.createElement('thead')
      thead.innerHTML = '<tr><th style="padding: 3px; border:2px solid gray">ESCANDALLO</th><th style="padding: 3px; border:2px solid gray">COSTE</th></tr>'
      tabla.appendChild(thead)
      var tbody = v.document.createElement('tbody')
      var tr = v.document.createElement('tr')
      tr.innerHTML = "<td style='padding: 3px; border:2px solid gray;'>COSTE MENÚ</td>";
      tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;color: #0086b3; font-size: larger; text-align: right'><b>" + parseFloat(i.precio || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</b></td>";
      tbody.appendChild(tr)
      
      var tr = v.document.createElement('tr')
      tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;'>TOTAL COSTE MENÚ <span style='color:red'>(DESV " + i.desviacion + "%)</span></td>";
      tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;color: #0086b3; font-size: larger; text-align: right'><b>" + parseFloat(parseFloat(i.precio || 0) * (1+(i.desviacion/100))).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</b></td>";
      tbody.appendChild(tr)

      var tr = v.document.createElement('tr')
      tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;'>PRECIO VENTA RECOMENDADO <span style='color:red'>(RATIO F&B " + i.ratioFnB + "%)</span></td>";
      tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;color: #0086b3; font-size: larger; text-align: right'><b>" + parseFloat(i.precioDesv || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</b></td>";
      tbody.appendChild(tr)

      var tr = v.document.createElement('tr')
      tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;'>PRECIO DEFINITIVO (sin IVA) <span style='color:red'>(RATIO F&B REAL " + parseFloat((parseFloat(i.precio || 0) * (1+(i.desviacion/100)) / parseFloat((i.pDef || i.precioDesv) || 0)).toFixed(2) * 100) + "%)</span></td>";
      tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;color: #0086b3; font-size: larger; text-align: right'><b>" + parseFloat((i.pDef || i.precioDesv) || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</b></td>";
      tbody.appendChild(tr)

      tabla.appendChild(tbody)
      wrapper.appendChild(tabla)

      wrapper.innerHTML += "<h1 style='text-align:center; color: red; font-weight: bold;margin-bottom:5px;font-family: \"Roboto\", sans-serif !important;'>MENÚ</h1>"

      var tabla = v.document.createElement('table')
      tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width: 100%; margin-top: 5px"
      var tbody = v.document.createElement('tbody')

      this.categorias.forEach(cat => {
        if(i.recetas.filter(rec => rec.categoria == cat.idCategoria).length > 0){
          var tr = v.document.createElement('tr')
          tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;text-align:center; font-weight: bold; color: #0086b3' colspan='4'>" + cat.nombre + "</td>";
          tbody.appendChild(tr)
          var tr = v.document.createElement('tr')
          tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;'>RECETA</td>";
          tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;'>U.D</td>";
          tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;'>COSTE</td>";
          tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;'>%</td>";
          tbody.appendChild(tr)
          i.recetas.filter(rec => rec.categoria == cat.idCategoria).forEach(re => {
            var tr = v.document.createElement('tr')
            tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;'>" + this.recetas.find(r => r.idReceta == re.idReceta).nombre + "</td>";
            tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;'>" + re.uds + "</td>";
            tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;color: #0086b3; font-size: larger; text-align: right'><b>" + parseFloat((re.precio || 0)*re.uds).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</b></td>";
            tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;color: #0086b3; font-size: larger; text-align: right'><b>" + parseFloat(parseFloat(((re.precio || 0) / (i.precio || 1)) * 100)*re.uds).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " %</b></td>";
            tbody.appendChild(tr)
          });
        }
        var tr = v.document.createElement('tr')
        tr.innerHTML += "<td colspan='4'></td>";
        tbody.appendChild(tr)
      });

      tabla.appendChild(tbody)
      wrapper.appendChild(tabla)

      v.document.write(wrapper.outerHTML)
      v.print()
      // v.close()
    },
    pr(i){
      var total = 0
      i.forEach(esc => {
        try{
          total += eval(this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.formula)[0].replaceAll(',','.').replaceAll('p', this.articulos.filter(art => art.idArticulo == this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.idArticulo)[0]).map(a => a.precio))) * esc.uds
        }
        catch(e){
          total += 0
        }
      });
      return total
    },
    parseDate,
    async deleteReceta({ idMenu, nombre }) {
			const confirmation = await this.$refs.confirm.open(
				`Receta: ${nombre}`,
        `ATENCIÓN: ESTA ACCIÓN ES IRREVERSIBLE SIN UNA COPIA DE SEGURIDAD. ¿Seguro que quieres eliminar el menu?`,
        { color: "error" }
      );
      if (!confirmation) return;

      const { data } = await axios({
        method: "DELETE",
        url: "/menus/" + idMenu,
      });
      this.$emit('reload')
    }
  }
};
</script>
